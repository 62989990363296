// import styles from "./WHYCHOOSEUS.module.css";
// import WhyLogo from "assets/WhyLogo.png";

// const WHYCHOOSEUS = () => {
//   return (
//     <div className={styles.main}>
//       <p className={styles.heading}>
//         <span>WHY CHOOSE US</span>
//       </p>
//       <div className={styles.container}>
//         <div className={styles.box}>
//             <div className={styles.containerBox}>
//           <h1 className={styles.subhead}>
//             <span className={styles.icon}>★</span>Expertise Across Industries:
//           </h1>
//           <p className={styles.subHeading}>
//             With experience in diverse sectors like aerospace, finance, and
//             renewable energy, we understand the unique challenges and needs of
//             your industry.
//           </p>
//             </div>
//             <div className={styles.containerBox}>
//           <h1 className={styles.subhead}>
//             <span className={styles.icon}>★</span>Comprehensive Services:
//           </h1>
//           <p className={styles.subHeading}>
//           From outsourcing and talent management to digital experience, we offer a full suite of IT services tailored to your business goals.
//           </p>
//             </div>
//         </div>
//         <div className={styles.box}>
//             <img src={WhyLogo} alt="Logo"  className={styles.photo}/>
//         </div>
//         <div className={styles.box}>
//             <div className={styles.containerBox}>
//           <h1 className={styles.subhead}>
//             <span className={styles.icon}>★</span>Commitment to Quality:
//           </h1>
//           <p className={styles.subHeading}>
//           Our team is dedicated to delivering high-quality solutions that meet your specific needs and drive your business forward.
//           </p>
//             </div>
//             <div className={styles.containerBox}>
//           <h1 className={styles.subhead}>
//             <span className={styles.icon}>★</span>Customer-Centric Approach:
//           </h1>
//           <p className={styles.subHeading}>
//           From outsourcing and talent management to digital experience, we offer a full suite of IT services tailored to your business goals.
//           </p>
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default WHYCHOOSEUS;
import styles from "./WHYCHOOSEUS.module.css";
import WhyLogo from "assets/WhyLogo.png";

const WHYCHOOSEUS = () => {
  return (
    <div className={styles.main}>
      <p className={styles.heading}>
        <span>WHY CHOOSE US</span>
      </p>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.containerBox}>
            <div className={styles.icon}>★</div>
            <div className="flex items-baseline justify-center flex-col">
              <h1 className={styles.subhead}>
                Expertise Across Industries:
              </h1>
              <p className={styles.subHeading}>
                With experience in diverse sectors like aerospace, finance, and
                renewable energy, we understand the unique challenges and needs of
                your industry.
              </p>
            </div>
          </div>
          <div className={styles.containerBox}>
            <div className={styles.icon}>★</div>
            <div className="flex items-baseline justify-center flex-col">
              <h1 className={styles.subhead}>
                Comprehensive Services:
              </h1>
              <p className={styles.subHeading}>
                From outsourcing and talent management to digital experience, we offer a full suite of IT services tailored to your business goals.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <img src={WhyLogo} alt="Logo" className={styles.photo} />
        </div>
        <div className={styles.box}>
          <div className={styles.containerBox}>
            <div className={styles.icon}>★</div>
            <div className="flex items-baseline justify-center flex-col">
              <h1 className={styles.subhead}>
                Commitment to Quality:
              </h1>
              <p className={styles.subHeading}>
                Our team is dedicated to delivering high-quality solutions that meet your specific needs and drive your business forward.
              </p>
            </div>
          </div>
          <div className={styles.containerBox}>
            <div className={styles.icon}>★</div>
            <div className="flex items-baseline justify-center flex-col">
              <h1 className={styles.subhead}>
                Customer-Centric Approach:
              </h1>
              <p className={styles.subHeading}>
                From outsourcing and talent management to digital experience, we offer a full suite of IT services tailored to your business goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WHYCHOOSEUS;
//★
