import React from "react";
import styles from "./Cards.module.css";
import { Link } from "react-router-dom";

const Cards = ({ imageSrc, altText, heading, paragraph,link }) => {
    return (
        <Link to={link}>
        <div className={styles.container}>
            <div className={styles.imgContainer}>
                <img src={imageSrc} alt={altText} className={styles.cardIcon} />
            </div>
            <div className={styles.textArea}>
                <h4 className={styles.subHeading}>{heading}</h4>
                <p className={styles.paragraph}>{paragraph}</p>
            </div>
        </div>
        </Link>
    );
};

export default Cards;
