import React from "react";
import styles from "./ServicesPage.module.css";
import Cards from "./Cards/Cards";
import one from "assets/icons/machinelearningicon.svg";
import two from "assets/icons/uxicon.svg";
import three from "assets/icons/blockchainicon.svg";
import four from "assets/icons/dataanalyicon.svg";
import five from "assets/icons/webdevelopment.svg";
import six from "assets/icons/interneticon.svg";

const ServicePage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1 className={styles.mainHead}>
          Our <span className={styles.addC}>Development</span> Services
        </h1>
        <p className={styles.paragraph}>
          Intigate offers services that include user-friendly navigation, the latest features, and maintaining standards as started in business needs.
        </p>
      </div>
      <div className={styles.cards}>
        <div className={styles.cardContainers}>
        <Cards
          imageSrc={one}
          altText="Machine Learning Solutions"
          heading="Machine Learning Solutions"
          paragraph="Our Machine Learning development services revolve around working with intuitive, comprehensive tools with automated feature engineering capabilities."
          />
          <div className={styles.customCard}>
        <Cards
          imageSrc={two}
          altText="UI & UX DESIGNING"
          heading="UI & UX DESIGNING"
          paragraph="We bring brand values by creating customized interactive user interfaces with deep graphic knowledge."
          />
          </div>
        <Cards
          imageSrc={three}
          altText="Blockchain Logo"
          heading="BLOCKCHAIN"
          paragraph="Due to its decentralized nature, Blockchain has ruled billions of hearts. We aim to spread it a little more by developing custom blockchain solutions for start-ups and Enterprises."
          />
          </div>
          <div className={styles.cardContainers}>

        <Cards
          imageSrc={four}
          altText="BI & DATA ANALYTICS"
          heading="BI & DATA ANALYTICS"
          paragraph="We help enterprises in finding new growth opportunities by analyzing raw & unprocessed data."
        />
          <div className={styles.customCard}>

        <Cards
          imageSrc={five}
          altText="WEB & MOBILE DEVELOPMENT"
          heading="WEB & MOBILE DEVELOPMENT"
          paragraph="Intigate offers solutions to help clients achieve faster, measurable, and efficient results."
          />
          </div>
        <Cards
          imageSrc={six}
          altText="INTERNET OF THINGS"
          heading="INTERNET OF THINGS"
          paragraph="We are a trusted consultancy company that helps drive productivity for your business requirements."
          />
          </div>
      </div>
    </div>
  );
};

export default ServicePage;
