import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiGlobalLine } from 'react-icons/ri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget)) {
      setIsDropdownOpen(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative" onMouseLeave={handleMouseLeave}>
      <button
        onMouseEnter={handleMouseEnter}
        className="flex items-center px-3 py-2 text-white rounded-lg bg-blue-500"
      >
        <RiGlobalLine className="w-6 h-6 mr-2" />
        Eng&nbsp;{isDropdownOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
      </button>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute grid w-44 grid-rows-1 mt-2 mr-4 text-white border-t-2 border-orange-400 bg-customGray top-8"
          onMouseEnter={handleMouseEnter}
        >
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Global / English</Link>
          {/* <Link to="#" onClick={() => changeLanguage('hr')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Croatia / Croatian</Link>
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">EMEA / English</Link>
          <Link to="#" onClick={() => changeLanguage('de')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Germany / Deutsch</Link>
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">India / English</Link>
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Israel / English</Link>
          <Link to="#" onClick={() => changeLanguage('ja')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Japan / 日本語</Link>
          <Link to="#" onClick={() => changeLanguage('es')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Latam / Español</Link>
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Nordic / English</Link>
          <Link to="#" onClick={() => changeLanguage('pl')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Poland / Polski</Link>
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Romania / English</Link>
          <Link to="#" onClick={() => changeLanguage('sk')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Slovakia / Slovensko</Link>
          <Link to="#" onClick={() => changeLanguage('uk')} className="px-3 py-1 rounded-lg hover:bg-blue-500">Ukraine / українська</Link>
          <Link to="#" onClick={() => changeLanguage('en')} className="px-3 py-1 rounded-lg hover:bg-blue-500">UK / English</Link> */}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;

