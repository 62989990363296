import React from 'react';
// import Navbar from 'Components/Navbar';
// import Footer from 'Components/Footer';
import bgimg from "../../images/digital_advisory.png"
import Card from './Card'
const BackgroundImageComponent = () => {
  return (
    <div>
      {/* <Navbar/> */}
    <div className="relative w-full ">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative bg-center bg-no-repeat bg-cover h-96 " style={{ backgroundImage: `url(${bgimg})` }}>
        <div className="relative z-10 p-8 text-left text-white top-20">
          <h2 className="lg:w-1/2 sm:w-full  mb-6 text-xl font-bold text-left sm:ml-10 sm:text-4xl">We Take Pride in Our Work</h2>
          <p className=" lg:w-1/2 sm:w-full  text-sm  sm:text-lg sm:ml-10">
            We have partnered with some of the world’s most prominent brands. Here are just a few examples of the work that we’ve done with our amazing clients.
          </p>
        </div>
      </div>
    </div>
    <Card/>
{/* <Footer/> */}

    </div>
  );
};

export default BackgroundImageComponent;
