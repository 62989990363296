import React from 'react';

function EditBlogModal({ currentBlog, setCurrentBlog, showEditModal, setShowEditModal, saving, handleSaveChanges }) {
  if (!showEditModal) return null;

  return (
    <div className="fixed w-full h-full inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white lg:w-1/2 md:w-3/4 sm:w-11/12 max-h-[80vh] p-6 rounded-lg shadow-lg overflow-auto">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">Edit Blog Post</h3>
      
      <label className="block mb-2 text-gray-600 font-medium">Content</label>
      <textarea
        className="w-full h-40 md:h-60 lg:h-40 p-3 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        value={currentBlog?.content || ''}
        onChange={(e) => setCurrentBlog({ ...currentBlog, content: e.target.value })}
      ></textarea>
      
      <div className="flex justify-end mt-4">
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-150 ease-in-out mr-2"
          onClick={() => setShowEditModal(false)}
        >
          Cancel
        </button>
        <button
          className={`px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-150 ease-in-out ${saving ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleSaveChanges}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>
    </div>
  </div>
  );
}

export default EditBlogModal;
