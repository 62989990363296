import React, { useState } from 'react'
import styles from "./Login.module.css"
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { BASE_APIURL } from 'utils/helpers';

const AdminLogin = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${BASE_APIURL}/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        console.log(data)
        localStorage.setItem('token', data.token); // Store JWT
        toast.success("Logged in Successfully")
        navigate('/admin/blog-posting'); // Redirect to dashboard
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData.message);
        toast.error(errorData.message)
      }
    } catch (error) {
      console.error('Login failed:', error);
      toast.error("An expected error occured . Please try again!")
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.container}>
      <form className={styles.loginForm} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Login</h2>

        {/* Email input */}
        <div className={styles.inputGroup}>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={styles.input}
          />
        </div>

        {/* Password input */}
        <div className={styles.inputGroup}>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className={styles.input}
          />
          <span className={styles.eyeIcon} onClick={togglePasswordVisibility}>
            {showPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>



        {/* Submit button */}
        <button type="submit" className={styles.loginButton}>
          LOGIN
        </button>

      </form>
      <Toaster />
    </div>
  )
}

export default AdminLogin
