import React from 'react';
import styles from './ServiceContract.module.css';

const ServiceContract = () => {
  return (
    <div className={styles.container}>
     <div className={styles.midcontainer}>
     <div className={styles.serviceItem}>
        <div className={styles.circle}>Compass Service Contract</div>
        <p>7 Years of Mechanical and Electrical Repairs</p>
      </div>

      <div className={`${styles.serviceItem} ${styles.notIncluded}`}>
        <div className={styles.circle}>Honda Care Maintenance Upgrade</div>
        <p>5 Years of Maintenance <br /> <span className={styles.notIncludedText}>Not Included</span></p>
      </div>

      <div className={`${styles.serviceItem} ${styles.notIncluded}`}>
        <div className={styles.circle}>Driver's Armor Cosmetic Repair</div>
        <p>5 Years of Minor Cosmetic Repairs <br /> <span className={styles.notIncludedText}>Not Included</span></p>
      </div>

      <div className={`${styles.serviceItem} ${styles.notIncluded}`}>
        <div className={styles.circle}>Compass Gap</div>
        <p>Total Loss Coverage for length of loan <br /> <span className={styles.notIncludedText}>Not Included</span></p>
      </div>
     </div>

      <div className={styles.footer}>
        <p>Service Contract Only Price: <strong>$3,100</strong></p>
        <p>New Monthly Car Payment: <strong>$598.14</strong></p>
      </div>
    </div>
  );
};

export default ServiceContract;
