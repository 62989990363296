import React from 'react';
import styles from './HeadQuarter.module.css';

const HeadQuarter = ({ mobileContent }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Honda Civic Headquarters</h1>
      <img className={styles.mobileimg} src="https://imgs.search.brave.com/ipSDa1-xgv-JQ0ACDopISSQ4CVV539ZUPupXTaqc3Iw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly93d3cu/cG5nYWxsLmNvbS93/cC1jb250ZW50L3Vw/bG9hZHMvMS9Nb2Jp/bGUtRnJlZS1Eb3du/bG9hZC1QTkcucG5n" />

      <div className={styles.office}>
        <div className={styles.computer}>
          <p>THAT WAS FAST</p>
        </div>

        <div className={styles.receptionist}>
          <p className={styles.speech}>At your service, Mr. Scott Santana!</p>
        </div>
      </div>

      <div className={styles.mobileDevice}>
      {mobileContent.img && <img src={mobileContent.img} alt="Mobile content" className={styles.mobileImage} />}
        {mobileContent.title && <p className={styles.mobileTitle}>{mobileContent.title}</p>}
        {mobileContent.line1 && <p className={styles.mobileContent}>{mobileContent.line1}</p>}
        {mobileContent.line2 && <p className={styles.mobileContent}>{mobileContent.line2}</p>}
        {mobileContent.phone && <p className={styles.mobileContent}>{mobileContent.phone}</p>}
      </div>
    </div>
  );
};

export default HeadQuarter;
