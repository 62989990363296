import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the CSS for the carousel
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import one from "assets/bgimage.png";
import sec from "assets/banner2.png";
import third from "assets/img3.png";
// import fourth from "assets/idea.jpg";
import fifth from "../../images/Big Data & Analyticsbanner.png"
import styles from "./ImageCarousel.module.css";

const ImageCarousel = () => {
  return (
    <Carousel
      className={styles.carouselImg}
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
    >
      <div>
        <img src={one} alt="Slide 1" className={styles.imgs} />
        <div className={styles.textContainer}>
          <h2>Machine Learning & AI</h2>
          <p>
            Automate And Advance Your Business With
            Innovation-Driven Artificial Intelligence
            & Machine Learning Solutions. Believing In
            Experimenting With Innovation, Curating
            Customer’s Expectations For Years Meeting
            Excellence, Providing Your Business A Global
            Presence.
          </p>
          <a href="#contact" className={styles.letsTalkButton}>Let’s Talk <span className={styles.arrowB}><FontAwesomeIcon icon={faArrowRight} size="xl" style={{ color: "#fcfcfc", }} /></span></a>
        </div>
      </div>
      <div>
        <img src={sec} alt="Slide 2" className={styles.imgs} />
        <div className={styles.textContainer}>
          <h5>SECURE & IT SERVICES</h5>
          <h2>Excellent IT Services For Your Success</h2>
          <p>
            To deliver the best digital products and solutions, we collaborate with both startups and large corporations.
          </p>
          <a href='#contact' className={styles.letsTalkButton}>Let’s Talk <span className={styles.arrowB}><FontAwesomeIcon icon={faArrowRight} size="xl" style={{ color: "#fcfcfc", }} /></span></a>
        </div>
      </div>
      <div>
        <img src={third} alt="Slide 3" className={styles.imgs} />
        <div className={styles.textContainer}>
          <h2>Web & Mobile Development for the Future</h2>
          <p>
          Accelerate your business with responsive web and mobile applications. Our tailored solutions help you achieve measurable results, creating seamless user experiences and enhancing digital engagement.
          </p>
          <a href='#contact' className={styles.letsTalkButton}>Let’s Talk <span className={styles.arrowB}><FontAwesomeIcon icon={faArrowRight} size="xl" style={{ color: "#fcfcfc", }} /></span></a>
        </div>
      </div>
      <div>
        <img src={fifth} alt="Slide 4" className={styles.imgs} />
        <div className={styles.textContainer}>
          <h2>Data-Driven Insights with BI & Analytics</h2>
          <p>
          Unlock new opportunities by transforming raw data into powerful insights. Our Business Intelligence and Analytics services help you make data-driven decisions to propel your business forward.
          </p>
          <a href='#contact' className={styles.letsTalkButton}>Let’s Talk <span className={styles.arrowB}><FontAwesomeIcon icon={faArrowRight} size="xl" style={{ color: "#fcfcfc", }} /></span></a>
        </div>
      </div>
    </Carousel>
  );
};

export default ImageCarousel;
