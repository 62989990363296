import React, { useEffect, useState } from 'react';
import EditBlogModal from './EditBlogModal';
import { useNavigate } from 'react-router-dom';
import { BASE_APIURL, getToken } from 'utils/helpers';

function BlogPost() {
	const navigate = useNavigate()
	const [blogs, setBlogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [currentBlog, setCurrentBlog] = useState(null);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);


	const fetchBlogs = async () => {
		const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
		try {
			const response = await fetch(`${BASE_APIURL}/get-blogs`, {
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});
			if (!response.ok) {
				throw new Error('Failed to fetch blogs');
			}
			const data = await response.json();
			setBlogs(data.htmlFiles);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchBlogs();
	}, []); // Removed `blogs` dependency to avoid unnecessary re-fetching

	const handleEdit = (blog) => {
		setCurrentBlog(blog);
		setShowEditModal(true);
	};

	const handleDelete = async (blogKey) => {
		setDeleting(true);
		const key = blogKey.split("/")[1]; // Extract the key from the blogKey string
		const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
		try {
			const response = await fetch(`${BASE_APIURL}/blog/html-files/${key}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			if (!response.ok) throw new Error(`Failed to delete blog: ${response.statusText}`);
			const jsonres = await response.json();
			console.log(jsonres)
			fetchBlogs()

			// Update the blogs state to remove the deleted blog
			// setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.key !== blogKey));
		} catch (err) {
			console.error('Delete blog error:', err);
			setError(err.message);
		} finally {
			setDeleting(false);
		}
	};

	const handleSaveChanges = async () => {
		setSaving(true);
		const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
		try {
			const response = await fetch(`${BASE_APIURL}/update-blog/${currentBlog.key}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify({ content: currentBlog.content }),
			});
			if (!response.ok) throw new Error('Failed to update blog');

			setBlogs((prevBlogs) =>
				prevBlogs.map((blog) =>
					blog.key === currentBlog.key ? { ...blog, content: currentBlog.content } : blog
				)
			);
			setShowEditModal(false);
		} catch (err) {
			setError(err.message);
		} finally {
			setSaving(false);
		}
	};

	return (
		<div className="p-4">
			<h2 className="text-2xl text-center font-semibold mb-4">Blog Posts</h2>

			{loading && <div className="text-center text-blue-500">Loading blogs...</div>}
			{error && <div className="text-center text-red-500 mb-4">{error}</div>}

			{!loading && blogs.length > 0 && (
				<div className="overflow-x-auto">
					<table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
						<thead>
							<tr>
								<th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600">ID</th>
								<th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600">Content Preview</th>
								<th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-gray-600">Actions</th>
							</tr>
						</thead>
						<tbody>
							{blogs.map((blog, index) => (
								<tr key={blog.key} className="hover:bg-gray-100">
									<td className="py-2 px-4 border-b border-gray-200">{index + 1}</td>
									<td className="py-2 px-4 border-b border-gray-200">{blog.content.slice(0, 50)}...</td>
									<td className="py-2 px-4 border-b border-gray-200">
										<button
											onClick={() => handleEdit(blog)}
											className="text-white bg-blue-500 p-2 m-2 rounded shadow-sm hover:bg-blue-600"
										>
											Edit
										</button>
										<button
											onClick={() => handleDelete(blog.key)}
											className="text-white bg-red-500 p-2 m-2 rounded shadow-sm hover:bg-red-600"
											disabled={deleting}
										>
											{deleting ? 'Deleting...' : 'Delete'}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}

			<EditBlogModal
				currentBlog={currentBlog}
				setCurrentBlog={setCurrentBlog}
				showEditModal={showEditModal}
				setShowEditModal={setShowEditModal}
				saving={saving}
				handleSaveChanges={handleSaveChanges}
			/>
		</div>
	);
}

export default BlogPost;