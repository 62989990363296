import React, { useEffect, useRef, useState } from "react";
import styles from "./DraftEditor.module.css"
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html"; // Import the library
import Toolbar from "./Toolbar/Toolbar";
import { useNavigate } from "react-router-dom";
import { BASE_APIURL } from "utils/helpers";
import toast, { Toaster } from "react-hot-toast";
// import "./DraftEditor.css";

const DraftEditor = () => {
  const navigate = useNavigate()
  const [showHtml, setShowHtml] = useState();
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      convertFromRaw({
        blocks: [
          {
            key: "3eesq",
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [
              {
                offset: 19,
                length: 6,
                style: "BOLD",
              },
              {
                offset: 25,
                length: 5,
                style: "ITALIC",
              },
              {
                offset: 30,
                length: 8,
                style: "UNDERLINE",
              },
            ],
            entityRanges: [],
            data: {},
          },
       
        ],
        entityMap: {},
      })
    )
  );
  const editor = useRef(null);

  useEffect(() => {
    focusEditor();
  }, []);

  const focusEditor = () => {
    editor.current.focus();
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    if (command === 'bold') {
      setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
      return true;
    }
    return false;
  };


  // Function to convert editor content to HTML
  const getHTML = async () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState, {
      blockRenderMap: {
        'header-one': {
          element: 'h1',
        },
        'header-two': {
          element: 'h2',
        },
        'header-three': {
          element: 'h3',
        },
        'header-four': {
          element: 'h4',
        },
        'header-five': {
          element: 'h5',
        },
        'header-six': {
          element: 'h6',
        },
        'blockQuote': {
          element: 'blockquote',
        },
        // Add more block types here if needed
      },
    });
    console.log(html); // Log the HTML for testing
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
    try {
      const response = await fetch(`${BASE_APIURL}/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ html }), // Convert the data to JSON string
      });
      if (!response.ok) {
        toast.error("some error occured !please try again")
        throw new Error("some error occured !please try again")
      }

      const result = await response.json();
      toast.success("blog post successfull")
      console.log('Response from server:', result);
    } catch (error) {
      console.error('Error sending data:', error);
      toast.error(error || "Some error occured !Please try again")
    }
    setShowHtml(html);
    return html;
    // try {
    //   const response = await fetch(`${BASE_APIURL}/upload`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       "Authorization": `Bearer ${token}`
    //     },
    //     body: JSON.stringify({ html }), // Convert the data to JSON string
    //   });

    //   const result = await response.json();
    //   console.log('Response from server:', result);
    // } catch (error) {
    //   console.error('Error sending data:', error);
    // }
    // setShowHtml(html);
    // return html;
  };


  const styleMap = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    HIGHLIGHT: {
      backgroundColor: "#F7A5F7",
    },
    UPPERCASE: {
      textTransform: "uppercase",
    },
    LOWERCASE: {
      textTransform: "lowercase",
    },
    CODEBLOCK: {
      fontFamily: '"fira-code", "monospace"',
      fontSize: "inherit",
      background: "#ffeff0",
      fontStyle: "italic",
      lineHeight: 1.5,
      padding: "0.3rem 0.5rem",
      borderRadius: "0.2rem",
    },
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "80%",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "80%",
    },
  };
  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();

    switch (type) {
      case "header-one":
        return styles.h1Style; // CSS Modules format for H1
      case "header-two":
        return styles.h2Style; // CSS Modules format for H2
      case "header-three":
        return styles.h3Style; // CSS Modules format for H3
      case "header-four":
        return styles.h4Style; // CSS Modules format for H4
      case "header-five":
        return styles.h5Style; // CSS Modules format for H5
      case "header-six":
        return styles.h6Style; // CSS Modules format for H6
      case "blockquote":
        return styles.blockquoteStyle; // Blockquote style
      case "unordered-list-item":
        return styles.unorderedListStyle; // Unordered list item style
      case "ordered-list-item":
        return styles.orderedListStyle; // Ordered list item style
      case "code-block":
        return styles.codeBlockStyle; // Code block style
      case "paragraph":
        return styles.paragraphStyle; // Paragraph style
      case "atomic":
        return styles.atomicStyle; // Atomic block (for custom media or embedded components)
      case "unstyled":
      default:
        return styles.defaultStyle; // Default style or unstyled block
    }
  };

  return (
    <div className={styles.adminMain}>
      <div className={styles.editorWrapper} onClick={focusEditor}>
        <Toolbar editorState={editorState} setEditorState={setEditorState} />
        <div className={styles.editorContainer}>
          <Editor
            ref={editor}
            placeholder="Tell us your story..."
            handleKeyCommand={handleKeyCommand}
            editorState={editorState}
            customStyleMap={styleMap}
            blockStyleFn={myBlockStyleFn}
            onChange={(editorState) => {
              setEditorState(editorState);
            }}
          />
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4" onClick={getHTML}>Post Blog</button> {/* Button to export */}
      </div>
      <Toaster />
      {/* <div >{showHtml}</div> */}
    </div>
  );
};

export default DraftEditor;
