import React from 'react';

// const imageUrl = 'http://provaantech.com/wp-content/uploads/2024/01/SF_cs_contact_centers_devops_thumb-1-1.jpg'; // Replace with your actual image URL
import bgimg from "../../images/cloudadoptionblog.jpg"

const GreatPlaceToWork = () => {
  return (
    <div className=" w-11/12 mx-auto p-8">
      <h2 className="text-4xl font-bold mb-4 text-center">Provaantech | Great Place to Work</h2>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left side content */}
        <div className="flex flex-col items-start justify-start">
          <h3 className="text-3xl font-semibold mb-4">Provaantech is proud to be certified as a Great Place to Work.</h3>
          <p className="mb-4 text-xl text-gray-500">
            We were assessed on various parameters like credibility, respect, camaraderie, pride and fairness and stood out in all these.
            The Great Place to Work® Certification is a gold standard 'Employer of Choice' recognition, with more than 10,000 organizations
            across 60 countries vying to get Great Place to Work–Certified™ every year.
          </p>
          <p className="mb-4 text-xl text-gray-500">
            Watch the video to learn more about our 5 key employee engagement drivers that help us foster a great culture,
            keeps us going and binds us together as one provaantech family.
          </p>
        </div>

        {/* Right side image */}
        <div className="flex justify-center items-center">
        <img src={bgimg} alt="Provaantech | Great Place to Work" className=" h-[22rem] w-[90%] rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default GreatPlaceToWork;
