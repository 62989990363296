import React, { useState, useEffect } from 'react';
import styles from './AdminJobPostForm.module.css';
import { useNavigate } from 'react-router-dom';
import { BASE_APIURL, getToken } from 'utils/helpers';

const AdminJobPostForm = () => {
	const navigate = useNavigate()
	const [jobData, setJobData] = useState({
		title: '',
		description: '',
		requirements: '',
		location: '',
		salaryRange: '',
	});

	const [jobPosts, setJobPosts] = useState([]);

	// Handle input changes
	const handleChange = (e) => {
		setJobData({ ...jobData, [e.target.name]: e.target.value });
	};

	// Submit a new job
	const handleSubmit = async (e) => {
		e.preventDefault();
		const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
		try {
			const response = await fetch(`${BASE_APIURL}/jobs`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify(jobData),
			});

			if (response.ok) {
				const result = await response.json();
				console.log('Job posted:', result);
				fetchJobs(); // Refresh job list after posting
				resetForm(); // Reset form after submission
			} else {
				console.error('Failed to post job:', response.statusText);
			}
		} catch (error) {
			console.error('Error posting job:', error);
		}
	};

	// Fetch job listings from the server
	const fetchJobs = async () => {
		const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
		try {
			const response = await fetch(`${BASE_APIURL}/jobs`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			if (!response.ok) {
				throw new Error('Failed to fetch jobs');
			}
			const data = await response.json();
			setJobPosts(data.jobPosts || []); // Ensure jobPosts is always an array
		} catch (error) {
			console.error('Error fetching jobs:', error);
		}
	};


	// Reset form fields
	const resetForm = () => {
		setJobData({
			title: '',
			description: '',
			requirements: '',
			location: '',
			salaryRange: '',
		});
	};

	// Fetch job posts when component mounts
	useEffect(() => {
		fetchJobs();
	}, []);

	return (
		<div className={styles.container}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<h2 className={styles.formTitle}>Post a Job</h2>
				<input type="text" name="title" placeholder="Job Title" value={jobData.title} onChange={handleChange} required />
				<textarea name="description" placeholder="Job Description" value={jobData.description} onChange={handleChange} required></textarea>
				<textarea name="requirements" placeholder="Job Requirements" value={jobData.requirements} onChange={handleChange} required></textarea>
				<input type="text" name="location" placeholder="Location" value={jobData.location} onChange={handleChange} required />
				<input type="text" name="salaryRange" placeholder="Salary Range" value={jobData.salaryRange} onChange={handleChange} required />
				<button type="submit" className={styles.submitButton}>Post Job</button>
			</form>

		</div>
	);
};

export default AdminJobPostForm;
