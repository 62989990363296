const OFFERINGS_NAV_LINKS = [
  // {
  //   id: 1,
  //   label: "Offerings",
  //   path: "/services/offerings",
  //   navStyles: "p-2 bg-gray-500 rounded-md hover:bg-gray-400"
  // },
  {
    id: 1,
    label: "Outsourcing and Talent Management...",
    path: "/services/offerings/Outsourcing-And-Talent",
    navStyles: "p-2 pl-4 mt-2 rounded-md rounded-md  hover:bg-blue-500"
  },
  {
    id: 2,
    label: "Digital Advisory & Assessments",
    path: "/services/offerings/Digital-Advisory",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 3,
    label: "Customer Experience & Design",
    path: "/services/offerings/cutomer-experience-design",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 4,
    label: "Technology & Engineering",
    path: "/services/offerings/technology-engineering",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 5,
    label: "Digital Accelerators",
    path: "/services/offerings/digital-accelerators",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 6,
    label: "Data & Artificial Intelligence",
    path: "/services/offerings/data-artificial-intelligence",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 7,
    label: "Cloud Adoption",
    path: "/services/offerings/cloud-adoption",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 8,
    label: "Contact Center Experience",
    path: "/services/offerings/contact-center-experience",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 9,
    label: "Generative AI Strategy & Solution",
    path: "/services/offerings/generative-ai-solutions",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  }
]


const INDUSTRIES_NAV_LINKS = [
  // {
  //   id: 1,
  //   label: "Industries",
  //   path: "/services/industries",
  //   navStyles: "p-2 bg-gray-500 rounded-md hover:bg-gray-400"
  // },
  {
    id: 1,
    label: "Automative, Aerospace & Defence",
    path: "/services/industries/Automotive-Aerospace",
    navStyles: "p-2 pl-4 mt-2 rounded-md  hover:bg-blue-500"
  },
  {
    id: 2,
    label: "Banking, Financial Services & Insurance",
    path: "/services/industries/Banking-Financial",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 3,
    label: "Communication, Media & Entertainment",
    path: "/services/industries/Communication-Media",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 4,
    label: "Consumer & Retail",
    path: "/services/industries/Consumer-Retail",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 5,
    label: "Healthcare & Life Sciences",
    path: "/services/industries/Healthcare-Life-Science",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 6,
    label: "Import Export, Manufacturing and Industry",
    path: "/services/industries/Import-Export-Manufacturing",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 7,
    label: "Oil & Gas",
    path: "/services/industries/Oil-Gas",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 8,
    label: "Technology",
    path: "/services/industries/Technology",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 9,
    label: "Power, Electrical & Electronics",
    path: "/services/industries/Power-Electrical",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 10,
    label: "Renewable Energy",
    path: "/services/industries/Renewable-Energy",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  }
]

const TECHNOLOGY_PRACTICES_NAV_LINKS = [
  // {
  //   id: 1,
  //   label: "Technology Practices",
  //   path: "/services/Technology",
  //   navStyles: "p-2 bg-gray-500 rounded-md hover:bg-gray-400"
  // },
  {
    id: 1,
    label: "Agile",
    path: "/services/technology/agile",
    navStyles: "p-2 pl-4 mt-2 rounded-md  hover:bg-blue-500"
  },
  {
    id: 2,
    label: "Architecture",
    path: "/services/technology/architecture",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 3,
    label: "AI & Machine Learning",
    path: "/services/technology/ai",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 4,
    label: "Big Data & Analytics",
    path: "/services/technology/bigdata",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 5,
    label: "Blockchain",
    path: "/services/technology/blockchain",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 6,
    label: "Cloud",
    path: "/services/technology/cloud",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 7,
    label: "Devops",
    path: "/services/technology/devops",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 8,
    label: "Digital Quality Assurance",
    path: "/services/technology/digital",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 9,
    label: "Embedded",
    path: "/services/technology/embedded",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 10,
    label: "Mobility",
    path: "/services/technology/mobility",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  },
  {
    id: 11,
    label: "Security",
    path: "/services/technology/security",
    navStyles: "p-2 pl-4 rounded-md  hover:bg-blue-500"
  }
]

export { OFFERINGS_NAV_LINKS, INDUSTRIES_NAV_LINKS, TECHNOLOGY_PRACTICES_NAV_LINKS }