import React from 'react';
import styles from './InfoBoard.module.css';

const InfoBoard = ({
    imageSrc1,
    imageSrc2,
    header,
    subheading,
    listItems,
    mainContent,
    sideContent,
    footerText,
    subFooterText
}) => {
    return (
        <div className={styles.infoBoardContainer}>
            {/* Render header if present */}
            {header && <header className={styles.header}>{header}</header>}
            
            {/* Render subheading if present */}
            {subheading && <div className={styles.subheading}>{subheading}</div>}

            {/* Mid content section */}
            <div className={styles.infoBoardMidContainer}>
                {
                    // Render images only if either imageSrc1 or imageSrc2 is provided
                    (imageSrc1 || imageSrc2) && (
                        <div  className={`${imageSrc2 && imageSrc1 ? styles.bothimageSection : styles.imageSection}`}>
                            {imageSrc1 && (
                                <img
                                    src={imageSrc1}
                                    alt="Image 1"
                                    className={`${styles.image1} ${!imageSrc2 ? styles.singleImage : styles.multiImagefirst}`}
                                />
                            )}
                            {imageSrc2 && (
                                <img
                                    src={imageSrc2}
                                    alt="Image 2"
                                    className={`${styles.image2} ${!imageSrc1 ? styles.singleImage : styles.multiImage}`}
                                />
                            )}
                        </div>
                    )
                }

                {/* Render list and main content if either is provided */}
                {(listItems || mainContent) && (
                    <section className={`${imageSrc2 && imageSrc1 ? styles.smallContentSection : styles.contentSection}`}>
                        <div className={styles.listAndContent}>
                            {listItems && listItems.length > 0 && (
                                <ul className={styles.list}>
                                    {listItems.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                            {mainContent && <div className={styles.mainText}>{mainContent}</div>}
                        </div>
                    </section>
                )}
            </div>

            {/* Render footer if present */}
            {footerText && <footer className={styles.footer}>{footerText}</footer>}
            
            {/* Render subFooter if present */}
            {subFooterText && <div className={styles.subFooter}>{subFooterText}</div>}
        </div>
    );
};

export default InfoBoard;
