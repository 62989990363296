// import styles from "./AboutUs.module.css";
// import img from "assets/img.png"
// import progressbarLogo from "assets/progressbarLogo.png";

// const About = () => {
//   const startYear = 2018;
//   const currentYear = 2024;
//   const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

//   return (
//     <div className={styles.main}>
//       <div className={styles.boxOne}>
//         <div className={styles.aboutBox}>
//           <h1 className={styles.heading}><span className={styles.head}>ABOUT US</span></h1>
//           <p className={styles.paragraph}>
//             ProvaanTech is a Software Product Engineering Services And Staffing
//             Company. We help you through various services from digital platforms
//             to Security. As IT service premier, we extend our expertise in
//             aerospace and defence, banking and finance, manufacturing, renewable
//             energy, media and entertainment industry.  We have the knowledge and
//             experience to tackle complex challenges and deliver innovative
//             solutions that drive your business forward. Whether you need software
//             development, digital platform creation, or robust security solutions,
//             we are here to support your IT needs at every stage.
//           </p>
//           <div className={styles.intro}>
//             <span className={styles.coFounder}>Co-founders</span>
//             <p className={styles.introP}>-Sneha Seth</p>
//           </div>

//         </div>
//         <div className={styles.duriationBar}>
//           <div className={styles.timelineContainer}>
//             <div className={styles.timeline}>
//               <div className={`${styles.timelineLogo} ${styles.start}`}>
//                 <img src={progressbarLogo} alt="Company Start" />
//               </div>
//               <div className={styles.progressBar}></div>
//               <div className={`${styles.timelineLogo} ${styles.end}`}>
//                 <img src={progressbarLogo} alt="Current Year" />
//               </div>
//             </div>
//             <div className={styles.years}>
//               {years.map(year => (
//                 <span key={year}>{year}</span>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className={styles.boxTwo}>
//         <div className={styles.imgBorder}>
//         </div>
//         <img src={img} alt="IMAGE" className={styles.aboutImg} />
//       </div>
//     </div>
//   );
// };
// export default About;


import styles from "./AboutUs.module.css";
import img from "assets/img.png"
import progressbarLogo from "assets/provaantechlogo.png";

const About = () => {
  const startYear = 2018;
  const currentYear = 2024;
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

  return (
    <div className={styles.main}>
      <div className={styles.boxOne}>
        <div className={styles.aboutBox}>
          <h1 className={styles.heading}><span className={styles.head}>ABOUT US</span></h1>
          <p className={styles.paragraph}>
            Provaantech is a Software Product Engineering Services And Staffing
            Company. We help you through various services from digital platforms
            to Security. As IT service premier, we extend our expertise in
            aerospace and defence, banking and finance, manufacturing, renewable
            energy, media and entertainment industry.  We have the knowledge and
            experience to tackle complex challenges and deliver innovative
            solutions that drive your business forward. Whether you need software
            development, digital platform creation, or robust security solutions,
            we are here to support your IT needs at every stage.
          </p>
          <div className={styles.intro}>
            <span className={styles.coFounder}>Co-founders</span>
            <p className={styles.introP}>-Sneha Seth</p>
          </div>

        </div>
        <div className={styles.duriationBar}>
          <div className={styles.timelineContainer}>
            <div className={styles.timeline}>
              <div className={`${styles.timelineLogo} ${styles.start}`}>
                <img src={progressbarLogo} alt="Company Start" />
              </div>
              <div className={styles.progressBar}></div>
              <div className={`${styles.timelineLogo} ${styles.end}`}>
                <img src={progressbarLogo} alt="Current Year" />
              </div>
            </div>
            <div className={styles.years}>
              {years.map(year => (
                <span key={year}>{year}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boxTwo}>
        <div className={styles.imgBorder}>
        </div>
        <img src={img} alt="IMAGE" className={styles.aboutImg} />
      </div>
    </div>
  );
};
export default About;
