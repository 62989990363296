import React, { useEffect, useState } from 'react';
import EditJobModal from './EditJobModal';
import { useNavigate } from 'react-router-dom';
import { BASE_APIURL, getToken } from 'utils/helpers';

function JobPost() {
  const navigate = useNavigate()
  const [jobPosts, setJobPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const fetchJobs = async () => {
    const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
    try {
      const response = await fetch(`${BASE_APIURL}/jobs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch jobs');
      }
      const data = await response.json();
      setJobPosts(data.jobPosts || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleEdit = (job) => {
    setCurrentJob(job);
    setShowEditModal(true);
  };

  const handleDelete = async (id) => {
    const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
    setDeleting(true);
    try {
      const response = await fetch(`${BASE_APIURL}/jobs/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to delete job');
      setJobPosts(jobPosts.filter(job => job.id !== id));
    } catch (err) {
      setError(err.message);
    } finally {
      setDeleting(false);
    }
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    const token = getToken()
    if (!token) {
      console.error("No token found, redirecting to login...");
      navigate("admin/adminlogin")
      return;
    }
    try {
      const response = await fetch(`${BASE_APIURL}/jobs/${currentJob.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(currentJob),
      });
      if (!response.ok) throw new Error('Failed to update job');
      setJobPosts(jobPosts.map(job => (job.id === currentJob.id ? currentJob : job)));
      setShowEditModal(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl text-center font-semibold mb-4">Job Listings</h2>

      {loading && <div className="text-center text-blue-500">Loading jobs...</div>}
      {error && <div className="text-center text-red-500 mb-4">{error}</div>}

      {!loading && jobPosts.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {jobPosts.map((job) => (
            <div key={job.id} className="bg-white p-6 border border-gray-200 shadow-md rounded-md">
              <h3 className="text-lg font-semibold mb-2">{job.title}</h3>
              <p className="text-gray-700 mb-2"><strong>Description:</strong> {job.description}</p>
              <p className="text-gray-700 mb-2"><strong>Requirements:</strong> {job.requirements}</p>
              <p className="text-gray-700 mb-2"><strong>Location:</strong> {job.location}</p>
              <p className="text-gray-700 mb-4"><strong>Salary Range:</strong> {job.salaryRange}</p>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEdit(job)}
                  className="text-white bg-blue-500 p-2 rounded shadow-sm hover:bg-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(job.id)}
                  className="text-white bg-red-500 p-2 rounded shadow-sm hover:bg-red-600"
                  disabled={deleting}
                >
                  {deleting ? 'Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <EditJobModal
        currentJob={currentJob}
        setCurrentJob={setCurrentJob}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        saving={saving}
        handleSaveChanges={handleSaveChanges}
      />
    </div>
  );
}

export default JobPost;
