import useScrollToTop from 'hooks/useScrollToTop';
import React, { useEffect, useState } from 'react'
import { BASE_APIURL, sanitizeTitle, truncateText } from 'utils/helpers';
import { Link } from 'react-router-dom';
import "./Blogs.css"
import bgimg from "../../images/Agile.jpg";
import cloudAdoptionBlogImg from "images/cloudadoptionblog.jpg"
const categories = [
  "Artificial Intelligence",
  "Blog",
  "Cloud",
  "Data Science",
  "Digital",
  "IT Service",
  "Management",
  "Marketing"
];

const Blogs = () => {
  useScrollToTop()


  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const extractTitle = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const title = doc.querySelector('h1, h2')?.innerText || 'Untitled';
    return title;
  };

  const extractContentSnippet = (htmlContent, length = 200) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const content = doc.body.innerText || ''; // Extract plain text from the HTML body
    return content.substring(0, length) + '...'; // Return a snippet of content
  };

  // Fetch blogs from backend on component mount
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Fetching blogs from backend API
        const response = await fetch(`${BASE_APIURL}/get-blogs`);

        if (!response.ok) {
          throw new Error('Failed to fetch blogs');
        }

        const data = await response.json();
        setBlogs(data.htmlFiles); // The array of HTML files
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  console.log("nlogs", blogs)

  if (loading) return <div>Loading blogs...</div>;

  // Show error state if an error occurs
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {/* <Navbar/> */}
      <div className="  relative w-full ">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative bg-cover bg-center bg-no-repeat h-96  " style={{ backgroundImage: `url(${bgimg})` }}>
          <div className="relative z-10   top-40 text-center text-white">
            <h2 className="text-6xl w-1/2 -ml-40 font-bold mb-4"> INSIGHT</h2>

          </div>
        </div>
      </div>
      <div className="p-6 mx-auto space-y-6 bg-white max-w-8xl rounded-xl">
        <h2 className="text-4xl font-bold text-center text-gray-800">OUR LATEST BLOG</h2>
        <div className="flex flex-wrap justify-center gap-4 text-center">
          {categories.map((category, index) => (
            <span
              key={index}
              className="px-3 py-2 text-lg font-semibold text-white bg-orange-400"
            >
              {category}
            </span>
          ))}
        </div>

      </div>


      <div className="w-full p-8 mx-auto max-w-7xl">
        <h2 className="mb-6 text-3xl font-bold">Case Studies</h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {blogs?.map((blog) => (

            <div key={blog.key} className="items-center overflow-hidden text-center bg-white rounded-lg shadow-xl">
              <div className="relative">
             
                <img src={cloudAdoptionBlogImg} className="object-cover w-full h-48" />
              </div>
              <div className="p-6">
                <h3 className="mb-2 text-lg font-semibold">
                  {extractTitle(blog.content)}
                </h3>
                <p className="mb-4 text-gray-600">
                  {extractContentSnippet(blog.content)} 
                </p>
                
                <Link to={`/insights/${blog.key.split("/")[1]}`}>
                  <button className='p-1 px-2 bg-blue-500 hover:scale-110'>
                    <span className="text-sm font-normal text-white">Learn More</span>
                  </button>
                </Link>
              </div>
            </div>
          ))}

       
        </div>
      </div>


      {/* <div className="w-full p-8 mx-auto max-w-7xl ">
        <h2 className="mb-6 text-3xl font-bold">Case Studies</h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {blogs?.map((blog) => (
            <div key={blog.key} className="items-center overflow-hidden text-center bg-white rounded-lg shadow-xl">
              <div className="relative">
                <img src={image} alt={title} className="object-cover w-full h-48" />
              </div>
              <div className="p-6">
                <h3 className="mb-2 text-lg font-semibold">{title}</h3>
                <p className="mb-4 text-gray-600">{blog.content.substring(0, 200)}</p>
                <Link to={`/insights/${sanitizeTitle(title)}`}>
                  <button className='p-1 px-2 bg-orange-400 rounded-md hover:scale-110'>
                    <span className="text-sm font-normal text-white">Learn More</span>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* <h1>Blogs</h1>
      <div style={{ width: "80%", margin: "0 auto", textAlign: "center" }} className="blogs-container">
        {blogs.length === 0 && <div>No blogs available</div>}
        {blogs.map((blog) => (
          <div
            key={blog.key}
            style={{
              marginTop: "20px",
              padding: "20px",
              textAlign: "left",
              borderBottom: "1px solid #ccc",
              maxWidth: "1000px", 
              margin: "20px auto", 
            }}
            className="custom-blog-content"
          >
            <h2>{blog.key.split("/").pop().replace(".html", "")}</h2> 
            <div dangerouslySetInnerHTML={{ __html: blog.content }} /> 
          </div>
        ))}
      </div> */}

      {/* <Footer/> */}

    </div>

  );
};

export default Blogs
