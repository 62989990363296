import ImageCarousel from "Components/Carousel/ImageCarousel";
import styles from "./Home.module.css"
import FooterDiv from "Components/Footer/FooterDiv";
import Navbar from "Components/Navbar";
import ServicePage from "Components/ServicesPage";
import React from "react";
import About from "views/about/AboutUs";
import Banner from "./Banner";
// import whyChooseus from "Components/WhyChooseus/WhyChooseus";
import HappyClient from "Components/HappyClient/HappyClient";
import Contact from "views/contact/Contact";

const Home = () => {
    return (
        <>
        <div className={styles.main} style={{backgroundColor:"black"}} id="home">
        <ImageCarousel/>
        <ServicePage/>
        </div>
        </>
    )
};

export default Home;