import React, { useEffect, useRef, useState } from "react";
import styles from "./Banner.module.css";
import img from "assets/bannerimage.png";

const AnimatedNumbers = ({ endValue, duration, label }) => {
  const [count, setCount] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = ref.current.getBoundingClientRect().top;
      const sectionVisible = sectionTop < window.innerHeight && sectionTop >= 0;

      if (sectionVisible) {
        setCount(0); 
        let start = 0;
        const increment = endValue / (duration / 16.67);

        const animate = () => {
          if (start < endValue) {
            start += increment;
            setCount(Math.ceil(start));
            requestAnimationFrame(animate);
          } else {
            setCount(endValue);
          }
        };

        animate();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [endValue, duration]);

  return (
    <div className={styles.info} ref={ref}>
      <h1 className={styles.h1}>
        {endValue >= 1000000 ? (count / 1000000).toFixed(1) + " M+" : count + "+"}
      </h1>
      <p className={styles.para}>{label}</p>
    </div>
  );
};

const Banner = () => {
  return (
    <div className={styles.main}>
      <img src={img} alt="IMG" className={styles.imgs} />
      <div className={styles.Maininfo}>
        <AnimatedNumbers endValue={1000} duration={1000} label="Projects Delivered" />
        <AnimatedNumbers endValue={1000} duration={1000} label="Tech experts On-board" />
        <AnimatedNumbers endValue={1000000} duration={1000} label="Investment Raised for Startups" />
      </div>
    </div>
  );
};

export default Banner;
