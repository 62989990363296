//new changes of Navbar//
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { INDUSTRIES_NAV_LINKS, OFFERINGS_NAV_LINKS, TECHNOLOGY_PRACTICES_NAV_LINKS } from 'utils/Navbarutils';

const ServiceDropdown = ({ toggleMenu }) => {
  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isMobileMode, setIsMobileMode] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleMouseEnter = () => {
    if (!isMobileMode) {
      setIsServiceDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobileMode) {
      setIsServiceDropdownOpen(false);
    }
  };

  const handleSubmenuToggle = (submenu) => {
    setActiveSubmenu((prevmenu) => prevmenu === submenu ? null : submenu)
  };

  //close all the navs once some link is clicked
  const handleNavigationclose = () => {
    setIsServiceDropdownOpen(false)//close the dropdown once we clck on any link -> this line is used when in desktop mode
    if (isMobileMode) toggleMenu()  //this line closes the hambuger menu when we are in mobile mode 
  }

  const handleServiceClick = () => {
    setActiveSubmenu("")
    if (isMobileMode) {
      setIsMobileDropdownOpen(!isMobileDropdownOpen);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileMode(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMobileDropdownOpen(false);
      }
    };


    if (isMobileDropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobileDropdownOpen]);

  console.log('mobile', isMobileDropdownOpen)
  console.log('desktop', isServiceDropdownOpen)
  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      ref={dropdownRef}
    >
      <div className="px-4 py-2 text-white rounded-md" onClick={handleServiceClick}>
        <span className='flex items-center'>Services&nbsp;<FontAwesomeIcon icon={faAngleDown} /></span>
      </div>

      {/* mobile screens */}
      {(isMobileMode && isMobileDropdownOpen) &&
        <div className="grid w-full grid-cols-1 py-1 px-4 mt-2 border-t-2 border-white-400 bg-black bg-opacity-90 top-20">
          <div className='w-full p-2 text-sm text-left text-white'>
            <ul>
              <li onClick={() => handleSubmenuToggle('offerings')} className='p-2 bg-gray-500 rounded-md '>Offerings</li>
              {activeSubmenu === 'offerings' && OFFERINGS_NAV_LINKS?.map(({ id, label, path, navStyles }) => {
                return (
                  <li key={id} className={navStyles}>
                    <Link to={path} onClick={handleNavigationclose} >{label}</Link>
                  </li>
                )
              })
              }
            </ul>
          </div>
          <div className='w-full p-2 text-sm text-left text-white'>
            <ul>
              <li onClick={() => handleSubmenuToggle('industries')} className='p-2 bg-gray-500 rounded-md hover:bg-gray-400'>Industries</li>
              {activeSubmenu === 'industries' && INDUSTRIES_NAV_LINKS?.map(({ id, label, path, navStyles }) => {
                return (
                  <li key={id} className={navStyles}>
                    <Link to={path} onClick={handleNavigationclose} >{label}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='w-full p-2 text-sm text-left text-white'>
            <ul>
              <li onClick={() => handleSubmenuToggle('technology')} className='p-2 bg-gray-500 rounded-md hover:bg-gray-400'>Technology Practices</li>
              {activeSubmenu === 'technology' && TECHNOLOGY_PRACTICES_NAV_LINKS?.map(({ id, label, path, navStyles }) => {
                return (
                  <li key={id} className={navStyles}>
                    <Link to={path} onClick={handleNavigationclose} >{label}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      }

      {/* desktop mode */}
      {(!isMobileMode && isServiceDropdownOpen) &&
        <div className='absolute pt-4 top-8'>
          <div className=' grid mx-w-6xl  grid-cols-12 py-1 px-4 mt-0 border-t-2 border-orange-400 bg-customGray bg-opacity-90 top-8'>
            <div className='col-span-4 p-2 flex  text-sm text-left text-white'>
              <ul>
                <li className='p-2 bg-gray-500 rounded-md hover:bg-gray-400'>Offerings</li>
                {OFFERINGS_NAV_LINKS?.map(({ id, label, path, navStyles }) => {
                  return (
                    <li key={id} className={navStyles}>
                      <Link to={path} onClick={handleNavigationclose} >{label}</Link>
                    </li>
                  )
                })
                }
              </ul>
            </div>
            <div className='col-span-4 p-2 text-sm text-left text-white'>
              <ul>
                <li className='p-2 bg-gray-500 rounded-md hover:bg-gray-400'>Industries</li>
                {INDUSTRIES_NAV_LINKS?.map(({ id, label, path, navStyles }) => {
                  return (
                    <li key={id} className={navStyles}>
                      <Link to={path} onClick={handleNavigationclose} >{label}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='col-span-4 p-2 text-sm text-left text-white'>
              <ul>
                <li className='p-2 bg-gray-500 rounded-md hover:bg-gray-400'>Technology Practices</li>
                {TECHNOLOGY_PRACTICES_NAV_LINKS?.map(({ id, label, path, navStyles }) => {
                  return (
                    <li key={id} className={navStyles}>
                      <Link to={path} onClick={handleNavigationclose} >{label}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default ServiceDropdown;

