
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RiGlobalLine } from "react-icons/ri";
import logo from '../assets/provaantechlogo.png'
// import provaantTechLogo from "../assets/provaantechlogo.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Service from './Navbar/ServiceNavbar'
import Eng from './Navbar/Eng'
// import two from "assets/Provaantech_logo.svg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);    
  const navigate = useNavigate();
  const location = useLocation();
  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isMouseLeaveFromNav, setIsMouseLeaveFromNav] = useState(false);
  const [isMouseLeaveFromDropdown, setIsMouseLeaveFromDropdown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMode, setIsmobileMode] = useState(false)
  const { t } = useTranslation();


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMouseEnter = (setDropdownState) => {
    setDropdownState(true);
  };

  const handleMouseLeave = (setDropdownState) => {
    setDropdownState(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(true);
    setExpanded(true);
  };

  const toggleDropdownOnLeave = () => {
    setIsDropdownOpen(false);
    setExpanded(false);
    setIsMouseLeaveFromDropdown(false)
  };

  const toggleServiceDropdown = () => {
    setIsServiceDropdownOpen(!isServiceDropdownOpen);
  };

  const toggleAboutDropdown = () => {
    setIsAboutDropdownOpen(!isAboutDropdownOpen);
  };

  const navigateHomeAndScroll = (section) => {
    if (location.pathname !== '/') {
      // First, navigate to home, then scroll to the section
      navigate('/');
      // Wait for the navigation to complete before scrolling
      setTimeout(() => {
        scrollToSection(section);
      }, 100); // Adjust the delay if needed
    } else {
      scrollToSection(section);
    }
  };

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ fontSize: "16px", fontWeight: "500" }}>
      <nav className="relative z-50 py-2 shadow-lg bg-black ">
        <div className="max-w-6xl px-4 mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex justify-between">
              <div className='flex '>
                <Link to="/">
                  <div className='flex items-center'>
                    <img src={logo} style={{ maxWidth: '110px' }}></img>
                    {/* <img src={provaantTechLogo} style={{ maxWidth: '10%' }}></img> */}
                    {/* <h1 className='text-xl text-white'>Provaantech</h1> */}
                  </div>
                </Link>
              </div>
            </div>
            <div className="relative items-center hidden space-x-6 lg:flex ">
              <Service />
              <Link to="/work" className="px-2 py-2 text-white rounded-md font-normal text-base hover:bg-blue-500">Work</Link>
              <Link to="/insights" className="px-2 py-2 text-white rounded-md font-normal text-base hover:bg-blue-500">Insights</Link>
              <Link to="/careers" className="px-2 py-2 text-white rounded-md font-normal text-base hover:bg-blue-500">Careers</Link>
              <div  onMouseEnter={() => handleMouseEnter(setIsAboutDropdownOpen)} onMouseLeave={() => handleMouseLeave(setIsAboutDropdownOpen)}>
                <a  onClick={() => navigateHomeAndScroll('about')} className="cursor-pointer px-2 py-2 text-white rounded-md  ">
                  <span  className="px-2 py-2 text-white rounded-md font-normal text-base hover:bg-blue-500">About</span>
                </a>
                {/* {isAboutDropdownOpen && (
                  <div className="absolute  grid max-w-6xl left-32 grid-cols-12 py-1 mt-4 border-t-2 border-orange-400 bg-customGray bg-opacity-90 top-12" onMouseLeave={toggleAboutDropdown}>
                    <div className='col-span-4 p-2 text-sm text-left text-white'>
                      <ul>
                        <li className='p-2 bg-gray-500 rounded-md hover:bg-gray-400'><Link to='/services/offerings'>About Us</Link></li>
                        <li className='p-2 pl-4 mt-2 rounded-md  hover:bg-blue-500'><Link to='/services/offerings/outsourcing-and-talent'>Sustainability</Link></li>
                        <li className='p-2 pl-4 rounded-md hover:bg-blue-500'><Link to='/services/offerings/digital-advisory-and-assessment'>Corporate Social Responsibility</Link></li>
                      </ul>
                    </div>
                    <div className='col-span-4 p-2 text-sm text-left text-white'>
                      <ul>
                        <li className='rounded-md p-2 bg-gray-500 hover:bg-gray-400'><Link to='#'>Locations</Link></li>
                        <li className='rounded-md p-2 pl-4 mt-2 hover:bg-blue-500'><Link to='#'>Engineering Center</Link></li>
                        <li className='rounded-md p-2 pl-4 hover:bg-blue-500'><Link to='#'>Regional Offices</Link></li>
                        <li className='rounded-md p-2 pl-4 hover:bg-blue-500'><Link to='#'>Method Studios</Link></li>
                      </ul>
                    </div>
                    <div className='col-span-4 p-2 text-sm text-left text-white'>
                      <ul>
                        <li className='rounded-md p-2 bg-gray-500 hover:bg-gray-400'><Link to='#'>News and Events</Link></li>
                        <li className='rounded-md p-2 pl-4 mt-2 hover:bg-blue-500'><Link to='#'>Events</Link></li>
                        <li className='rounded-md p-2 pl-4 hover:bg-blue-500'><Link to='#'>Awards</Link></li>
                        <li className='rounded-md p-2 pl-4 hover:bg-blue-500'><Link to='#'>Press Release</Link></li>
                      </ul>
                    </div>
                  </div>
                )} */}
              </div>

              {/* <a href="/about" className="px-2 py-2 text-white rounded-md font-normal text-base hover:bg-blue-500">About</a> */}
              <a  className="px-2 py-2 text-white rounded-md font-normal text-base hover:bg-blue-500"  onClick={() => navigateHomeAndScroll('contact')} >Contact</a>
              <Eng />
            </div>

            <div className="flex items-center lg:hidden">
              <button onClick={toggleMenu} className="mobile-menu-button">
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

{/* mobile mode */}
        <div className={`mobile-menu ${isMenuOpen ? '' : 'hidden'} lg:hidden absolute bg-black w-screen`}>
          <Service toggleMenu={toggleMenu} />
          {/* <Link to="/services" className="block px-4 py-2 text-sm text-white rounded-sm hover:bg-blue-500">SERVICE</Link> */}
          <Link to="/work" onClick={toggleMenu} className="block px-4 py-2 text-sm text-white rounded-sm hover:bg-blue-500">Work</Link>
          <Link to="/insights" onClick={toggleMenu} className="block px-4 py-2 text-sm text-white rounded-sm hover:bg-blue-500">Insights</Link>
          <Link to="/careers" onClick={toggleMenu} className="block px-4 py-2 text-sm text-white rounded-sm hover:bg-blue-500">Careers</Link>
          <a  onClick={() => navigateHomeAndScroll('about')} className="cursor-pointer block px-4 py-2 text-sm text-white rounded-sm hover:bg-blue-500">About</a>
          <a  onClick={() => navigateHomeAndScroll('contact')} className="cursor-pointer block px-4 py-2 text-sm text-white rounded-sm hover:bg-blue-500">Contact</a>
        </div>
      </nav>

    </div>
  );
};

export default Navbar;