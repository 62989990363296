const countryCodes = [
    { code: "+1", country: "United States" },
    { code: "+44", country: "United Kingdom" },
    { code: "+91", country: "India" },
    { code: "+81", country: "Japan" },
    { code: "+86", country: "China" },
    { code: "+33", country: "France" },
    { code: "+49", country: "Germany" },
    { code: "+39", country: "Italy" },
    { code: "+34", country: "Spain" },
    { code: "+61", country: "Australia" },
    { code: "+55", country: "Brazil" },
    { code: "+27", country: "South Africa" },
    { code: "+7", country: "Russia" },
    { code: "+52", country: "Mexico" },
    { code: "+60", country: "Malaysia" },
    { code: "+65", country: "Singapore" },
    { code: "+63", country: "Philippines" },
    { code: "+20", country: "Egypt" },
    { code: "+90", country: "Turkey" },
    { code: "+971", country: "United Arab Emirates" },
    { code: "+92", country: "Pakistan" },
    { code: "+48", country: "Poland" },
    { code: "+31", country: "Netherlands" },
    { code: "+41", country: "Switzerland" },
    { code: "+46", country: "Sweden" },
    { code: "+47", country: "Norway" },
    { code: "+32", country: "Belgium" },
    { code: "+420", country: "Czech Republic" },
    { code: "+43", country: "Austria" },
    { code: "+372", country: "Estonia" },
    { code: "+370", country: "Lithuania" },
    { code: "+371", country: "Latvia" },
    { code: "+385", country: "Croatia" },
    { code: "+386", country: "Slovenia" },
    { code: "+354", country: "Iceland" },
  ];
  
  export default countryCodes;
  