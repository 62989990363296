import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from "../../../assets/provaantechlogo.png"

const Navbar = ({ isSuccessFullLogin, setIsSuccessFullLogin }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setIsSuccessFullLogin(false);
    navigate('/admin');
  };

  // Helper function to apply active class
  const isActive = (path) => location.pathname === path ? styles.active : '';

  return (
    <nav className={styles.navbar}>
      <div className={styles.navContainer}>
        {/* Logo */}
        <div className={styles.logo}>
          <a href="/admin">
            <img src={logo} />
          </a>
        </div>

        {/* Desktop Menu */}
        {isSuccessFullLogin && (
          <>
            <div className={styles.menu}>
              <Link to="/admin" className={`${styles.menuItem} ${isActive('/admin')}`}>
                Home
              </Link>
              <Link to="/admin/blog-posting" className={`${styles.menuItem} ${isActive('/admin/blog-posting')}`}>
                Post Blog
              </Link>
              <Link to="/admin/blog-edit" className={`${styles.menuItem} ${isActive('/admin/blog-edit')}`}>
                Blog Edit
              </Link>
              <Link to="/admin/job-posting" className={`${styles.menuItem} ${isActive('/admin/job-posting')}`}>
                Post Job
              </Link>
              <Link to="/admin/job-edit" className={`${styles.menuItem} ${isActive('/admin/job-edit')}`}>
                Job Edit
              </Link>

              <button className={styles.logoutButton} onClick={handleLogout}>Logout</button>
            </div>

            <div className={styles.hamburger} onClick={toggleMenu}>
              <svg
                className={styles.hamburgerIcon}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </div>
          </>
        )}
      </div>

      {/* Mobile Menu */}
      {isSuccessFullLogin && (
        <div className={`${styles.mobileMenu} ${isOpen ? styles.open : ''}`}>
        
          <Link to="/admin" onClick={toggleMenu} className={`${styles.mobileMenuItem} ${isActive('/admin')}`}>
                Home
              </Link>
              <Link to="/admin/blog-posting" onClick={toggleMenu} className={`${styles.mobileMenuItem} ${isActive('/admin/blog-posting')}`}>
                Post Blog
              </Link>
              <Link to="/admin/blog-edit" onClick={toggleMenu} className={`${styles.mobileMenuItem} ${isActive('/admin/blog-edit')}`}>
                Blog Edit
              </Link>
              <Link to="/admin/job-posting" onClick={toggleMenu} className={`${styles.mobileMenuItem} ${isActive('/admin/job-posting')}`}>
                Post Job
              </Link>
              <Link to="/admin/job-edit" onClick={toggleMenu} className={`${styles.mobileMenuItem} ${isActive('/admin/job-edit')}`}>
                Job Edit
              </Link>

          <div className={styles.mobileMenuItem} onClick={handleLogout}>Logout</div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
