export const sanitizeTitle = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, '');    // Remove leading or trailing hyphens
};

export const truncateText = (text, length) => {
  if (text.length <= length) return text;
  return text.substring(0, length) + "...";
};

export const getToken = () => {
  try {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    return token
  } catch (error) {
    console.log(error)
    return null
  }
}


// export const BASE_APIURL = "http://localhost:3001"
export const BASE_APIURL="https://ds3s15234f.execute-api.ap-south-1.amazonaws.com/dev"
