import React, { useState, useEffect } from 'react';
import InfoBoard from './InfoBoard';
import ServiceContract from './ServiceContract';
import HeadQuarter from './HeadQuarter';

function Board() {
    const imageSrc1 = "https://imgs.search.brave.com/Euef_cRLyXgT2NXVjKFaoUdIgj0lXmI18ne5xsWS-Q8/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9wbHVz/cG5nLmNvbS9pbWct/cG5nL2Nhci1wbmct/Y2FyLXBuZy1maWxl/LXBuZy1pbWFnZS0x/MzEyLnBuZw";
    const imageSrc2 = "https://imgs.search.brave.com/HY_i0DwRNoH9Bg02gwLukcxjfpmnNG9XksrvTWwobNQ/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly93d3cu/c3B5bmUuYWkvYmxv/Z3Mvd3AtY29udGVu/dC91cGxvYWRzLzIw/MjMvMDEvY2FyLWlu/dGVyaW9yLmpwZw";
    
    // Now use these variables inside the object
    const infoData = [
      {
        id: 1,
        heading: "Before we finalize your selection, it is important to note:",
        listItems: [
          "Now is the only time your lender will include a service contract into your loan structure",
          "This is not an extended warranty",
        ],
      },
      {
        id: 2,
        heading: "100% Satisfaction Guaranteed",
        listItems: [
          "Millions of dollars in paid repairs",
          "Especially valuable for Hi-Tech cars like your C",
        ],
      },
      {
        id: 3,
        heading: "Wear and tear is covered",
        subheading: "Seals and Gaskets are included",
        listItems: [
            "1.5 liter turbocharged Inline ",
            "4 Cylinder Dual Overhead Cam",
          "4 Valves per Cylinder",
          "Variable Valve Timing",
          "180 hp @ 6,000 rpm",
          "34 Combined MPG",
        ],
        imageSrc1: imageSrc1, // Using the variable
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 4,
        heading: "Even though your Civic hasn't broken down",
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 5,
        heading: "Extended Warranties in the past:",
        subheading: "Covered Items",
        content:"Cylinder Block, Cylinder Head(s), Rotary Housing and All Internally Lubricated Parts contained within the engine including Pistons: Piston Rings, Connecting Rod Bearings, Crankshaft: Crankshaft Main Bearings...",
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 6,
        heading: "Compass Service Contract",
        subheading: "30,000 Parts that are covered",
        content: "Would be a 120 page book to list them all",
      },
      {
        id: 7,
        heading: "What is not covered",
        listItems: [
          "Non-factory installed radios and speakers",
          "Light bulbs, lenses, and sealed beams",
          "Upholstery",
          "Carpet, paint, ornamental moldings and interior trim",
          "Convertible tops, glass, plastic, framing, cables or seals",
        ],
        imageSrc1: imageSrc1, // Using the variable
        imageSrc2: imageSrc2, // Using the variable
        footer: "Covered Items = EVERYTHING ELSE",
      },
      {
        id: 8,
        heading: "Your CV (Continuously Variable) Transmission",
        imageSrc1: imageSrc1, // Using the variable
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 9,
        heading: "Your Drive Axle Assembly",
        imageSrc1: imageSrc1, // Using the variable
      },
      {
        id: 10,
        listItems: [
          "Your Fuel System",
          "Your Cooling System",
          "Your Steering System",
          "Your Braking System",
        ],
        imageSrc1: imageSrc1, // Using the variable
      },
      {
        id: 11,
        heading: "Includes ABS and Collision mitigation system",
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 12,
        heading: "Part of your Civic's 5 Star Safety Rating ",
        subheading: "Keeps you safe on the road",
        imageSrc1: imageSrc1, // Using the variable
      },
      {
        id: 13,
        heading: "Your Front and Rear Suspension",
        subheading: "Including:",
        listItems: ["Shocks and Springs", "Linkages and Controls", "Electronic Sensors"],
        footer: "We want you to Always enjoy a great ride",
        imageSrc1: imageSrc1, // Using the variable
      },
      {
        id: 14,
        heading: "Your Entire Electrical System",
        content: "10 X just a few years ago",
        footer: "When you press ANY button or control",
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 15,
        heading: "Every Electric item in your civic is covered",
        imageSrc2: imageSrc2, // Using the variable
      },
      {
        id: 16,
        heading: "Your A/C System",
        content: "Freon has been banned",
        footer: "Bad for Environment",
        imageSrc1: imageSrc1, // Using the variable
      },
      {
        id: 17,
        heading: "Your A/C System",
        subheading: "R 1234a is now used instead (Non-Freon based)",
        listItems: ["Good for environment", "Bad for seals and O-rings"],
        imageSrc1: imageSrc1, // Using the variable
      },
        {
          id: 18,
          heading: "Your A/C System",
          subheading: "Other companies don't cover everything",
          content: "A potential $2,800 repair that they don't want to pay for",
          imageSrc1: imageSrc1
        },
        {
          id: 19,
          heading: "We won't leave you hanging",
          content: "Aaaaahhh.... That FEELING you get when the A/C kicks in during the summertime!",
          subheading: "When it goes away, we bring it back!",
          footer: "For 7 Years or 100,000 Miles",

          imageSrc2: imageSrc2        },
        {
          id: 20,
          heading: "All Onboard Computers",
          subheading: "Relays and Sensors",
          content: "Over 200 On your Civic",
          footer: "The First thing you will notice",
          imageSrc2: imageSrc2
        },
        {
          id: 21,
          heading: "We will pay to diagnose and fix the problem",
          imageSrc1: imageSrc1 ,
          imageSrc2: imageSrc2
        },
      ];


    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % infoData.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + infoData.length) % infoData.length);
    };

    // Keydown event listener to handle left and right arrow keys
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                handleNext();
            } else if (event.key === 'ArrowLeft') {
                handlePrev();
            }
        };

        // Add event listener when the component mounts
        window.addEventListener('keydown', handleKeyDown);

        // Remove event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const mobileContent = {
      img: "https://imgs.search.brave.com/2wt6cWJ2Fa3-X5Z3AsJmyDi26HuRp7SmWna8CaIiNuI/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5pc3RvY2twaG90/by5jb20vaWQvMTc3/Mjk5MDg1L3Bob3Rv/L21hZ25ldGljLXdv/cmRzLWNvbGxlY3Rp/b24uanBnP3M9NjEy/eDYxMiZ3PTAmaz0y/MCZjPUYteWZFOTM4/dmRXZHNnN3FJSDBm/a21iY2Niek1qY29Z/VVlBSEJwTEJabjQ9",
      line1: "Roadside Assistance",
      line2: "Available 24/7",
      phone: "800-458-7071"
    };


    return (
        <div style={{ width: '60%', height: '500px', margin: '4rem auto' }}>
       
            <InfoBoard
                key={infoData[currentIndex].id}
                header={infoData[currentIndex].heading}
                subheading={infoData[currentIndex].subheading}
                listItems={infoData[currentIndex].listItems}
                mainContent={infoData[currentIndex].content}
                footerText={infoData[currentIndex].footer}
                imageSrc1={infoData[currentIndex].imageSrc1}
                imageSrc2={infoData[currentIndex].imageSrc2}

            />
    

            {/* <InfoBoard
                imageSrc1="https://imgs.search.brave.com/SMjRlpLSIyTUYzcHsQBLOOlvxUjLxd9tW3m5YX933Tc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9wbHVz/cG5nLmNvbS9pbWct/cG5nL2Nhci1wbmct/Y2FyLXBuZy1maWxl/LXBuZy1pbWFnZS0x/MzEyLnBuZw"
                header="Before we finalize your selection, it is important to note:"
                imageSrc2="https://imgs.search.brave.com/SMjRlpLSIyTUYzcHsQBLOOlvxUjLxd9tW3m5YX933Tc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9wbHVz/cG5nLmNvbS9pbWct/cG5nL2Nhci1wbmct/Y2FyLXBuZy1maWxl/LXBuZy1pbWFnZS0x/MzEyLnBuZw"
                subheading="subheading"
                mainContent="Now is the only time your lender will include a service contract into your loan structure"
                footerText="footer"

            /> */}

            <ServiceContract />
            <HeadQuarter mobileContent={mobileContent} />
        </div>
    );
}

export default Board;

