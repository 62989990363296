import React from "react";
import styles from "./HappyClient.module.css";
import client1 from "assets/client1.png";
import client2 from "assets/client2.png";
import client3 from "assets/client3.png";
import client4 from "assets/client4.png";

const HappyClient = () => {
  const data = [
    {
      name: "Emma Wilson",
      rating: 5,
      description:
        "Partnering with Provaantech transformed our business's approach to digital platforms. Their expertise in software development helped us launch a secure, user-friendly platform for our clients. The team was highly professional and exceeded our expectations. Highly recommended!",
      image: client1
    },
    {
      name: "David Thompson",
      rating: 5,
      description:
        "Provaantech has been a game-changer for our aerospace projects. Their knowledge of industry standards and attention to detail made our project a success. From development to security integration, they handled everything seamlessly. Exceptional work!",
      image: client4
    },
    {
      name: "Alex Kim",
      rating: 5,
      description:
        "Provaantech's AI solutions revolutionized our data processing. Their team implemented algorithms that enhanced our efficiency and predictive accuracy, allowing us to make better, data-driven decisions. Truly transformative experience!",
      image: client3
    },
    {
      name: "Mia Chen",
      rating: 5,
      description:
        "Working with Provaantech's AI experts helped us build a custom solution for automated customer support. Their cutting-edge tech reduced our response time significantly and improved customer satisfaction. Highly recommend their services!",
      image: client2
    }
];

  
  return (
    <div className={styles.main}>
      <div>
        <p className={styles.heading}>
          <span>
            Our Happy<span className={styles.back}> Clients</span>
          </span>
        </p>
      </div>
      <div className={styles.carousel}>
        <div className={styles.cardWrapper}>
          {data.concat(data).map((client, index) => (
            <div key={index} className={styles.card}>
              <div className={styles.HappyClientImg}>
                <img
                  src={client.image}
                  alt={client.name}
                  className={styles.image}
                />
                <div className={styles.box}>
                  <h3 className={styles.name}>{client.name}</h3>
                  <div className={styles.rating}>
                    {"★".repeat(client.rating)}
                  </div>
                </div>
              </div>
              <p className={styles.description}>{client.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HappyClient;
