import React from 'react';
import Navbar from 'Components/Navbar'; // Adjust the path according to your project structure
import Footer from 'Components/Footer'; // Adjust the path according to your project structure
import { FaUsers, FaHandsHelping, FaComments, FaChalkboardTeacher } from 'react-icons/fa';
import Jobcontainer from './Jobcontainer';
import Card1 from './card1'
import Card2 from './card2'
import Card3 from './card3'
import JobPostList from './JobPostList';
import bgimg from "../../images/TechnologyEngineeringbanner.png"

import Innovation from "../../images/website image/careers image/innovation image.png";
import Benefits from "../../images/website image/careers image/benefits.png";
import Growth from "../../images/website image/careers image/growth.png";
import Reward from "../../images/website image/careers image/Reward & Recognition.png";
import EmployeeValue from "../../images/website image/careers image/evp image.png";
import ProfessionalDevelopment from "../../images/website image/careers image/Professional Development.png";


const CareerSection = () => {
  return (
    <div>
      {/* <Navbar/> */}

      {/* <div className="relative w-full">
        <img
          src={bgimg}
          alt="Background"
          className=" bg-cover w-full  h-96"
        />
        <div className="absolute top-40 p-6 ">
          <h2 className="  text-white w-2/3  mb-6 text-xl font-bold text-left sm:ml-10 sm:text-4xl">We Are Looking fot Talented Testers</h2>
          <p className="text-white w-2/3 text-sm  sm:text-lg sm:ml-10">
            Unlock your full potential with us and pave the way to a fulfilling, successful, and dynamic career journey ahead.
          </p>
        </div>
      </div> */}

      <div className="relative w-full ">
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <div className="relative bg-center bg-no-repeat bg-cover h-96 " style={{ backgroundImage: `url(${bgimg})` }}>
        <div className="relative z-10 p-8 text-left text-white top-20">
          <h2 className="lg:w-1/2 sm:w-full mb-6 text-xl font-bold text-left sm:ml-10 sm:text-4xl">We Are Looking fot Talented Testers</h2>
          <p className=" lg:w-1/2 sm:w-full  text-sm  sm:text-lg sm:ml-10">
          Unlock your full potential with us and pave the way to a fulfilling, successful, and dynamic career journey ahead.
          </p>
        </div>
      </div>
    </div>



      <Card2 />
      <Card3 />
      {/* <Jobcontainer/> */}
      {/* <Card1/> */}
      <JobPostList />
      {/* <Footer/> */}
    </div>
  );
}

export default CareerSection;
