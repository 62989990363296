import React, { useState, useEffect } from 'react';
import styles from "./Contact.module.css";
import whatsappicon from "assets/whatsappicon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import countryCodes from './countryCodes';

const Contact = () => {
  const [mathAnswer, setMathAnswer] = useState("");
  const [error, setError] = useState("");
  const [mathQuestion, setMathQuestion] = useState("");
  const [mathAnswerValue, setMathAnswerValue] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [file, setFile] = useState(null);

  const generateMathQuestion = () => {
    const num1 = Math.floor(Math.random() * 6) + 1;
    const num2 = Math.floor(Math.random() * 4) + 1;
    return {
      question: `${num1} + ${num2} = `,
      answer: (num1 + num2).toString()
    };
  };

  useEffect(() => {
    const { question, answer } = generateMathQuestion();
    setMathQuestion(question);
    setMathAnswerValue(answer);
  }, []);

  const handleMathValidation = (e) => {
    const answer = e.target.value;
    setMathAnswer(answer);

    if (answer !== mathAnswerValue) {
      setError("Please enter the correct answer.");
    } else {
      setError("");
    }
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (error === "") {
      const formData = new FormData(e.target);
      formData.append('countryCode', countryCode);
      if (file) {
        formData.append('file', file);
      }

      emailjs
        .sendForm(
          'service_fa2zewc',      // Replace with your EmailJS service ID
          'template_hymge8h', // Replace with your EmailJS template ID
          e.target,                // Form element
          'b0beot2yp57e4uEuF'      // Replace with your EmailJS user ID
        )
        .then(
          (result) => {
            console.log('Email sent:', result.text);
            alert('Your message has been sent successfully!');
            e.target.reset();
            setCountryCode("+91");
            setFile(null);
          },
          (error) => {
            console.log('Error:', error.text);
            alert('There was an error sending your message.');
          }
        );
    }
  };

  const redirectToWhatsApp = () => {
    const phoneNumber = "+918123870326";
    const message = encodeURIComponent("Hello, I would like to chat with you!");
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url, "_blank");
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.boxOne}>
          <h1 className={styles.heading}>
            Estimations & Planning For<br />
            <span style={{ color: "#0B93D4" }}> Business Decisions</span>
          </h1>
          <p className={styles.paragraph}>
            We are a Digital Product Engineering company that is scaling in a big way! We build products, services, and experiences that inspire, excite, and delight. We work at scale across all devices and digital mediums.
          </p>
          <button onClick={redirectToWhatsApp} className={styles.btn}>
            <img className={styles.btnImg} src={whatsappicon} alt="WhatsApp" />
            <span>We are always excited to chat!</span>
          </button>
        </div>
        <div className={styles.boxTwo}>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <input type="text" name="name" placeholder="Name" className={styles.name} required />
              <input type="email" name="email" placeholder="Email" className={styles.email} required />
            </div>
            <div className={styles.formGroup}>
              <select className={styles.mobnum} name="countryCode" onChange={handleCountryCodeChange} value={countryCode}>
                {countryCodes.map(({ code, country }) => (
                  <option key={code} value={code}>{`${code}`}</option>
                ))}
              </select>
              <input type="text" name="phoneNumber" placeholder="Phone Number / Whatsapp" className={styles.num} required />
            </div>
            {/* <div className={styles.formGroup}>
              <input type="text" name="whatsappId" placeholder="Whatsapp/Skype ID" className={styles.inputspecial} />
              <select className={styles.inputspecial} name="budgetRange">
                <option value="">Select Budget Range</option>
                <option value="$5,000 - $10,000">$5,000 - $10,000</option>
                <option value="$10,001 - $20,000">$10,001 - $20,000</option>
                <option value="$20,001 - $30,000">$20,001 - $30,000</option>
                <option value="$30,001 - $40,000">$30,001 - $40,000</option>
                <option value="$40,001 - $50,000">$40,001 - $50,000</option>
                <option value="$50,001 - $100,000">$50,001 - $100,000</option>
                <option value="$100,001+">$100,001+</option>
              </select>
            </div> */}
            <div className={styles.formDes}>
              <label>How can we help your business?</label>
              <textarea name="help" required />
            </div>
            <div className={styles.uploadContainer}>
              <div className={styles.upload}>
                <label className={styles.uploadArea}>
                  <input type="file" name="file" onChange={handleFileChange} />
                  <span className={styles.uploadButton}>
                    <FontAwesomeIcon icon={faPlus} size="xl" style={{ color: "#ffffff", }} />
                  </span>
                </label>
                <p className={styles.files}>Attach your company profile.
                  <span className={styles.check}> (less than 10MB)</span>
                </p>
              </div>
            </div>
            <div className='m-2'>
              <label>{mathQuestion}</label>
              <input
                className={`${styles.answerBox} ${error ? styles.errorBorder : ''}`}
                type="text"
                value={mathAnswer}
                onChange={handleMathValidation}
                required
              />
            </div>
            <button className={styles.submitButton} type="submit" disabled={error !== ""}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
